.app {
    font-family: 'Nunito Sans', sans-serif;
}

h1 {
    font-weight: 900;
}

h2 {
    font-weight: 800;
}

h3 {
    font-weight: 700;
}

h4 {
    font-weight: 700;
}
